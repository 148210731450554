* {
  padding: 0;
  margin: 0;
}

body {
  background-image: url('./assets/backImagePk.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #9ab0ca;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 920px;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 54px;
  color: white;
  margin-bottom: 24px;
  text-align: center;
}

.content {
  font-size: 20px;
  color: white;
  line-height: 28px;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 300;
}

.card {
  width: 920px;
  padding: 40px;
  border-radius: 25px;
  border: solid rgba(255, 255, 255, 0.499) 1px;
  margin: 15px 30px;
}

.cardNone {
  display: none;
}

/* HTML: <div class="loader"></div> */
.loader {
  margin: 30px 0;
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  -webkit-mask: conic-gradient(from 15deg, #0000, #000);
  animation: l26 1s infinite steps(12);
}

.loader,
.loader:before,
.loader:after {
  background:
    radial-gradient(closest-side at 50% 12.5%,
      rgba(50, 96, 149, 0.40) 96%, #0000) 50% 0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%,
      rgba(50, 96, 149, 0.40) 96%, #0000) 0 50%/80% 20% repeat-x;
}

.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}

.loader:after {
  transform: rotate(60deg);
}

@keyframes l26 {
  100% {
    transform: rotate(1turn)
  }
}
.loaderBtn {
  width: 20px;
  margin: 0 auto;
  aspect-ratio: 1;
  display: grid;
  -webkit-mask: conic-gradient(from 15deg, #0000, #000);
  animation: lbtn 1s infinite steps(12);
}

.loaderBtn,
.loaderBtn:before,
.loaderBtn:after {
  background:
    radial-gradient(closest-side at 50% 12.5%,
      rgba(50, 96, 149, 0.40) 96%, #0000) 50% 0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%,
      rgba(50, 96, 149, 0.40) 96%, #0000) 0 50%/80% 20% repeat-x;
}

.loaderBtn:before,
.loaderBtn:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}

.loaderBtn:after {
  transform: rotate(60deg);
}

@keyframes lbtn {
  100% {
    transform: rotate(1turn)
  }
}

.errorText {
  color: rgb(187, 23, 23);
  font-size: 14px;
}

.card h4 {
  font-size: 20px;
  line-height: 22px;
  color: white;
  margin-bottom: 20px;
}

.items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  cursor: pointer;
}

.item {
  flex: 1;
  padding: 22px 20px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.150);
  display: block;
  color: white;
  backdrop-filter: blur(10px);
  font-size: 18px;
}

.inputEmail {
  flex: 1;
  padding: 22px 20px;
  font-size: 18px;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.150);
  display: block;
  color: white;
  backdrop-filter: blur(10px);
  border: none;
}

.inputBlock{
  flex: 1;
  flex-direction: column;
}

.inputEmail::placeholder {
  color: rgba(255, 255, 255, 0.600);
  /* Замените на желаемый цвет */
  opacity: 1;
  /* Убедитесь, что цвет виден */
}

.inputEmail:focus {
  outline: none;
}

.sendBtn {
  flex: 2;
  padding: 22px 20px;
  border-radius: 40px;
  background-color: white;
  display: block;
  color: #326095;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.used {
  color: rgb(180, 28, 28);
  font-size: 16px;
  margin-top: 5px;
}

.privacyText {
  font-size: 16px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 800px) and (max-width: 1200px) {
  .card {
    width: 600px;
  }

  .container {
    width: 600px;
  }
}

@media (min-width: 600px) and (max-width: 801px) {
  body {
    background-image: url('./assets/backImageMobile.png');
  }

  .card {
    width: 400px;
  }

  .items {
    flex-direction: column;
  }

  .container {
    width: 500px;
  }
}

@media (min-width: 450px) and (max-width: 601px) {
  .card {
    width: 320px;
    margin: 10px 15px;
    padding: 15px;
  }

  body {
    background-image: url('./assets/backImageMobile.png');
  }

  .items {
    flex-direction: column;
  }

  .container {
    width: 380px;
    margin: 40px auto;
  }

  .title {
    font-size: 36px;
  }

  .content {
    font-size: 18px;
  }

  .card h4 {
    font-size: 18px;
  }

  .sendBtn {
    padding: 18px 16px;
    font-size: 16px;
  }

  .inputEmail {
    font-size: 16px;
  }

  .item {
    font-size: 16px;
  }

  .privacyText {
    font-size: 14px;
  }

  .errorText {
    font-size: 13px;
  }

  .used {
    font-size: 14px;
  }
}

@media (min-width: 0px) and (max-width: 451px) {
  .card {
    width: 100%;
    margin: 10px 15px;
    padding: 0;
    border: none;
  }

  body {
    background-image: url('./assets/backImageMobile.png');
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .items {
    flex-direction: column;
    gap: 10px;
  }

  .container {
    width: calc(100% - 50px);
    margin: 40px 25px;
  }

  .title {
    font-size: 34px;
    text-align: left;
    margin-bottom: 15px;
    width: 100%;
  }

  .content {
    font-size: 16px;
    width: 100%;
    text-align: left;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .card h4 {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .sendBtn {
    padding: 16px 20px;
    font-size: 15px;
  }

  .inputEmail {
    font-size: 15px;
    padding: 16px 20px;
  }

  .item {
    font-size: 15px;
    padding: 16px 20px;
  }

  .privacyText {
    font-size: 13px;
    text-align: center;
  }

  .errorText {
    font-size: 12px;
  }

  .used {
    font-size: 13px;
  }
}